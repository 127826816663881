import { axiosBackEndInstance } from '../Axios';
import { END_POINT_USER, END_POINT_AUTH_REGISTER } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useGetUsers } from './useGetUsers';

export interface User{
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  role: string
}

export const useUsers= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetUsers()

    // const getUser = async ():Promise<User> =>{
    //   try{
    //     const  data = await axiosBackEndInstance.post(
    //        `${END_POINT_USER}/${id}`
    //     )
    //   }catch(error){

    //   }
    // }

     
     const createUser = async (
      callback: Function,
      values:{
        email: string,        
        first_name: string,        
        last_name: string, 
        password: string,
        role: string
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_AUTH_REGISTER, values
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        callback()
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Usuario creado con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      
    }
  };

  const editUser = async (
    callback: Function,
    id: number,
    values:{
      email: string,
      first_name: string,
      last_name: string, 
    }
                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_USER}/${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Usuario editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteUser = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_USER}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Usuario eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  return {
    editUser,
    createUser,
    deleteUser
  };
};
